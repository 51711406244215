import React, { useEffect } from "react";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import Partners from "@/components/Home/Partners";
import Breadcrumb from "@/components/Common/Breadcrumb";
import { useLocation } from "@reach/router";
import News from "@/components/News";
import BlogLayout from "@/components/Layout/BlogLayout";
import { useBlog } from "@/context/BlogContext";

const PostsByCategory = ({ params }: any) => {
  const { setCategory, clearStorage } = useBlog();
  const { category } = params;

  useEffect(() => {
    ["author", "search", "tag"].forEach((el) => {
      clearStorage(el);
    });
    setCategory(category);
    window.localStorage.setItem("category", category);
  }, [category]);

  return (
    <Layout>
      <SEO title="News" canonical={`${useLocation().host}`} />
      <Breadcrumb name="Latest Posts" pathName="Category" />
      <BlogLayout>
        <News />
      </BlogLayout>
      <Partners />
    </Layout>
  );
};

export default PostsByCategory;
